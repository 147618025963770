import React from "react";
import { Link } from "react-router-dom";
import "./App.css";

function Home() {
  return (
    <div className="full-container">
      {/* Top Section with Headers */}
      <div className="top">
        <h1 className="top-1">SUNSET WOJAK</h1>
        <h2 className="top-2">WE ARE ALL GONNA MAKE IT</h2>
      </div>

      {/* Middle Section with SUNSET WOJAK and Sun */}
      <div className="container-above">
        <div className="container">
          <div className="sky"></div>
          <div className="sea">
            <div className="light"></div>
          </div>
          <div className="sun"></div>
          <img
            src="/sunsetwojak.png"
            alt="SUNSET WOJAK"
            // height="auto"
            className="in-and-out"
          />
        </div>
      </div>

      {/* Footer Section */}
      <div className="both">
        <Link to="/gallery">
          <button className="crazy-button">
            <span className="text">Memes</span>
          </button>
        </Link>

        <div className="social-buttons">
          {/* <a
            href="https://x.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button"
          >
            <img src="/twitter-logo.png" alt="Twitter" />
          </a> */}
          <a
            href="https://t.me/wagmiontron"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button"
          >
            <img src="/telegram-logo.png" alt="Telegram" />
          </a>
          <a
            href="https://sunpump.meme/home"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button"
          >
            <img src="/sunpump.png" alt="sunpump" />
          </a>
        </div>
        <div className="footer-text">
          $WAGMI is a meme coin with no intrinsic value or expectation of
          financial return. It is not affiliated with the creator of wojak in
          any way.
        </div>
      </div>
    </div>
  );
}

export default Home;
