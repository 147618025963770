import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./App.css";

const images = [
  'image1.jpg',
  'image2.jpg',
  'image3.jpg',
  'image4.jpg',
  'image1.png',
  'image2.png',
  'image3.png',
  'image4.png',
  'image5.png',
  'image6.png',
  'image7.png',
  'image8.png',
  'image9.png',
  'image10.png',
  'image11.png',
  'image12.png',

];

function Home() {
  return (
    <div className="App-gallery">
      <header className="App-header-gallery">
        <h1 className="top-text font1">
          SUNSET WOJAK
          <br />
          <span className="font2"> The Only Optomistic Wojak </span>
          <br />
        </h1>
        <h2 style={{ marginTop: "-10px", marginBottom: '40px', color: '#fff' }} className="text-4 font1">
          SUNNY AF
        </h2>
        <Link to="/">
          <button className="crazy-button">
            <span className="text">Return Home</span>
          </button>
        </Link>
      </header>
      
        <div className="photo-grid">
          {images.map((image, index) => (
            <div key={index} className="photo-grid-item">
              <LazyLoadImage
                src={image}
                alt={`Gallery ${index + 1}`}
                effect="blur" // You can choose other effects like 'opacity' or 'black-and-white'
              />
            </div>
          ))}
        </div>
        <div className="footer-text font1 text-center">
        $WAGMI is a meme coin with no intrinsic value or expectation of
          financial return. It is not affiliated with the creator of wojak in any way.
        </div>
    </div>
  );
}

export default Home;
